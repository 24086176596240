<template>
  <v-container id="container">
    <v-row>
      <v-col cols="12">
         <h2>Café das Mulheres</h2>
         <h3>25 de Novembro às 19h</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Inscreva-se</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label for="">Nome</label>
        <v-text-field label="Insira seu nome" v-model="nome" solo class="input"></v-text-field>

        <label for="">Whatsapp</label>
        <v-text-field label="(00) 00000-0000" v-mask="['(##) #####-####']" v-model="telefone" solo class="input"></v-text-field>

        <label for="">Vai criança? Sugerimos que  não para que esse momento seja só seu, para que você dê abertura para o Espírito Santo agir sem preocupações, mas caso não seja possível, venha da mesma forma, se sim, qual a idade?</label>
        <v-text-field label="" type="number" v-model="idade" solo class="input"></v-text-field>
        <br>
        <h3>
          Taxa de inscrição: <strong>R$ 10,00</strong>
        </h3>
        <span>
          PIX CNPJ: <strong @click="copiar">29.750.192/0001-35</strong>
        </span>
        <br><br>
        <v-btn depressedcolor="primary" v-if="enviando == false" block @click="enviar">
          Enviar
        </v-btn>

        <v-btn depressedcolor="primary" v-if="enviando" block>
          Aguarde...
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mask} from 'vue-the-mask'
import axios from 'axios';

  export default {
    name: 'Home',
    directives: {mask},

    data(){
      return{
        nome: '',
        telefone: '',
        idade: 0,
        enviando: false,
      }
    },

    methods:{
      async enviar(){
        try {
          let dados = {
            nome: this.nome,
            telefone: this.telefone,
            idade: this.idade
          }

          if(!dados.nome){
            alert("Insira seu nome.");
            return
          }
          if(!dados.telefone){
            alert("Insira seu telefone.");
            return
          }

          this.enviando = true;
        
          await axios.post("https://tendaapi-eec1a152bb95.herokuapp.com/insere", dados)
          alert("Inscrição realizada com sucesso!");
          setTimeout(() => {
            window.location.href = 'https://www.instagram.com/tendadaadoracaoubt/'
          }, 5000);
        } catch (error) {
          console.log(error)
        }
      },
      copiar(){
        navigator.clipboard.writeText('29.750.192/0001-35');
        alert("CNPJ copiado!");
      }
    }

  }
</script>


<style scoped>
 @media screen and (max-width: 720px) {
    #container{
      color: #f5f5f5;
      width: 100% !important;
    }
 }

 #container{
  color: #f5f5f5;
  width: 400px;
 }
 .input{
  margin-bottom: -15px !important;
 }

 strong{
   cursor: pointer;
 }
</style>