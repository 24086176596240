import Vue from 'vue'
import VueRouter from 'vue-router'
// VIEWS
import Home from '../components/Home.vue'
import Conferencia from '../components/Conferencia.vue'
import Cep from '../components/Cep.vue'
Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    component: Home
  },
  { 
    path: '/cafedasmulheres', 
    component: Conferencia
  },
  {
    path: "/cep",
    component: Cep
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
