<template>
  <v-container class="home">
    <h2>HOME</h2>
    <v-text-field label="" type="number" v-model="texto" solo class="input"></v-text-field>

    <h4>{{ texto }}</h4>

</v-container>
</template>

<script>
  export default {
    name: 'Home',

    data(){
      return{
        texto: '',
      }
    },
    methods:{
      async envio(){
        
      }
    }
  }
</script>

<style>
  .home{
    color: #fff
  }
</style>
