<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>


<style scoped>
#app{
  background-color: #050505;
}
</style>