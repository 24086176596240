<template>
     <v-container class="home">
        <label for="">cep</label>
        <v-text-field label="Insira seu cep" v-model="cep" solo class="input"></v-text-field>
        <v-btn depressedcolor="primary" block @click="consultaCep">
          Enviar
        </v-btn>
        <ul>
            <li>
                CEP: {{ retorno.cep }}
            </li>
            <li>
                cidade: {{ retorno.city }}
            </li>
        </ul>
</v-container>
 </template>
<script>
import axios from 'axios';
export default {
    name: "CEP",
    data(){
      return{
        cep: '',
        dados: {},
        retorno: {}
      }
    },
    methods:{
        async consultaCep(){
          let response = await axios.get("https://brasilapi.com.br/api/cep/v1/"+this.cep)
          this.retorno = response.data
        }
    }
}
</script>

<style scoped>

</style>